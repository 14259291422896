@import "../component/pagination";
@import  "../section/section-text/section-text-align";

/* general styling
----------------------------------------------------------------------------------------------*/
.banner-content {
  figure {
    &::before,
    &::after {
      position: absolute;
      width: 100%;
      content: "";
      right: 0;
    }
    &::before {
      height: 267px;
      opacity: 0.3;
      background: linear-gradient(180deg, #1B1D22 0%, rgba(27, 29, 34, 0.00) 100%);
      top: 0;
    }
    &::after {
      height: 230px;
      background: linear-gradient(360deg, #000 40%, rgba(0, 0, 0, 0) 100%);
      bottom: 0;
      top: unset;
    }
    img {
      height: auto;
    }
  }
  &__caption {
    top: unset;
    transform: unset;
    bottom: 70px;
    h6 {
      margin: 0 0 8px;
      font-size: 1rem;
      color: var(--c-neutral-white);
      letter-spacing: 5px;
    }
    .title-medium {
      margin: 0;
      font-size: 2.5rem;
      font-weight: 700;
    }
  }
}

.sectiontext {
  padding: 0;
  margin-bottom:32px;
  p {
    margin-bottom: 12px;
  }
}
.section-content {
  position: relative;
  overflow: hidden;
  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: "";
    z-index: -1;
  }
  &::before {
    width: 752px;
    height: 1015px;
    background: url('/images/material/bg-content-top.png') no-repeat 0 0;
    top: 0;
    margin-top: -335px;
  }
  &::after {
    width: 100%;
    height: 500px;
    background: url('/images/material/bg-content-bottom.png') no-repeat 0 0;
    bottom: 0;
  }
  &.pt-large {
    padding-top: 200px;
  }
}

.pagination {
  a {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    line-height: 38px;
    margin: 0 4px;
  }
}




/* responsive desktop < 1400
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
  .banner-content {
    &__caption {
      .title-medium {
        font-size: 2.25rem;
      }
    }
  }
  .sectiontext {
    h4 {
      font-size: 1.75rem;
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .banner-content {
    figure {
      img {
        min-height: 480px;
      }
    }
    &__caption {
      bottom: 48px;
      h6 {
        font-size: 0.75rem;
      }
      .title-medium {
        font-size: 1.875rem;
      }
    }
  }
  .sectiontext {
    h4 {
      font-size: 1.625rem;
    }
  }
  .section-content {
    &.pt-large {
      padding-top: 150px;
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1023px) {}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .banner-content {
    figure {
      img {
        min-height: 400px;
      }
    }
    &__caption {
      h6 {
        font-size: 0.625rem;
      }
      .title-medium {
        font-size: 1.5rem;
      }
    }
  }
  .sectiontext {
    margin-bottom: 30px;
    h4 {
      font-size: 1.125rem;
    }
    p {
      font-size: 0.875rem;
      margin-bottom: 0;
    }
  }
  .content-pages {
    figure {
      margin: 0 0 0;
      img {
        min-height: 240px;
        object-fit: cover;
      }
    }
    &.content-detail {
      figure {
        margin-bottom: 26px;
        img {
          min-height: 138px;
        }
      }
    }
  }
  .section-content {
    &.pt-large {
      padding-top: 120px;
    }
  }
}
