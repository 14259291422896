.pagination {
  display: flex;
  max-width: 100%;
  a {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 1rem;
    color: var(--c-neutral-gray-3);
    text-align: center;
    line-height: 30px;
    margin: 0 6px;
    &:hover {
      color: var(--c-primary-hover);
    }
    &.active,
    &:active {
      color: var(--c-primary-pressed);
    }
    &.active {
      font-weight: 700;
    }

    &.prev,
    &.next {
      background-color: var(--c-primary-main);
      position: relative;
      &::before {
        content: "\ea17";
        font-family: var(--font-webarq-icon);
        color: var(--c-neutral-white);
        font-size: 22px;
      }
      &:hover {
        background-color: var(--c-primary-hover);
      }
      &:active {
        background-color: var(--c-primary-pressed);
      }
    }
    &.prev {
      margin: 0 32px 0 0;
    }
    &.next {
      margin: 0 0 0 32px;
      &::before {
        content: "\ea18";
      }
    }
  }

  &__large {
    a {
      margin: 0 8px;
      &.prev {
        margin: 0 40px 0 0;
      }
      &.next {
        margin: 0 0 0 40px;
      }
    }
  }
  &__small {
    a {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 0.875rem;
      margin: 0 4px;
      &.prev,
      &.next {
        &::before {
          font-size: 16px;
        }
      }
      &.prev {
        margin: 0 20px 0 0;
      }
      &.next {
        margin: 0 0 0 20px;
      }
    }
  }
  &.center {
    justify-content: center;
  }
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .pagination {
    a {
      font-size: .875rem;
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
    &__large {
      a {
        margin: 0 4px;
        &.prev,
        &.next {
          &:before{
            font-size: 18px;
          }
        }
        &.prev {
          margin-right: 24px;
        }
        &.next {
          margin-left: 24px;
        }
      }
    }
  }
}