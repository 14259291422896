.search-list{
  &__result{
    font-weight: 400;
    margin: 40px 0 60px 0;
    span{
      font-weight: 700;
    }
  }
  &__listing{
    &--inner{
      border-bottom: 2px solid var(--c-neutral-gray-1);
      padding-bottom: 40px;
      margin-bottom: 40px;
      &:last-child{
        border: 0;
      }
      h6{
        font-weight: 600;
        margin-bottom: 8px;
      }
      p{
        color: var(--c-neutral-gray-5);
        margin-bottom: 16px;
      }
      .button{
        padding-left: 0;
      }
    }
  }
}
/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1200px) {}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1023px) {}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 767px) {}
