/*=============================================================================================
		Company    : PT Web Architect Technology - webarq.com
		Document   : Stylesheet
		Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/


/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import  "../component/misc";
@import "../component/search";
@import "../component/pagination";
@import "general";


/* misc styling
----------------------------------------------------------------------------------------------*/
.section-content {
  h4 {
    margin: 0 0 16px;
  }
  &::before,
  &::after {
    content: unset;
  }
}
.sectiontext {
  p {
    color: var(--c-neutral-gray-2);
  }
}
.search-list {
  &__listing {
    &--inner {
      padding: 24px;
      border-radius: 8px;
      background: linear-gradient(271deg, #FFF -1.22%, #FDF7F0 107.29%);
      border: none;
      margin-bottom: 24px;
      h6 {
        font-weight: 500;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 12px;
      }
    }
  }
}
a {
  &.readmore {
    font-size: 0.875rem;
  }
}
.pagination {
  margin-top: 60px;
}

.misc {
  .bg {
    &::before {
      content: unset;
    }
  }
  .wrap-misc {
    h2 {
      text-transform: uppercase;
    }
    h6 {
      font-size: 1rem;
      letter-spacing: 5px;
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .misc {
    &__wrapper {
      width: auto;
      max-width: 100%;
      margin-left: 40px;
      margin-right: 40px;
    }
    .wrap-misc {
      &::before,
      &::after {
        content: unset;
      }
      &__center {
        &.undermaintenance {
          max-width: 792px;
        }
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .misc {
    &__wrapper {
      margin-left: 16px;
      margin-right: 16px;
    }
    .wrap-misc {
      h6 {
        font-size: 0.625rem;
      }
      h2 {
        font-size: 2rem;
        margin: 0 0 20px;
      }
      &__left {
        padding: 0 16px;
        p {
          font-size: 1rem;
          margin-bottom: 20px;
        }
      }
      &__center {
        &.undermaintenance {
          padding: 0 16px;
        }
      }
      &__right {
        max-width: 100%;
      }
    }
  }
  .search-list {
    &__listing {
      &--inner {
        h6 {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-word;
          overflow: hidden;
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
          overflow: hidden;
        }
      }
    }
  }
}