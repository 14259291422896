/*=============================================================================================
		Company    : PT Web Architect Technology - webarq.com
		Document   : Stylesheet
		Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/

/* misc styling
----------------------------------------------------------------------------------------------*/
.misc {
	position: relative;
	height: 100vh; 
	&.bg-blue {
		background-color: var(--c-primary-surface);
	}
	.bg {
		width: 100%;
		height: 100%;
		position: absolute;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			background: url('/images/material/shd-bg-misc.png') no-repeat;
			background-size: cover;
		}
	}	
	&__wrapper {
		min-height: 100vh;
		height: auto;
	}
	.wrap-misc {
		padding: 133px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100vh; 
		&__left {
			flex: 0 0 calc(100% - 540px);
			max-width: calc(100% - 540px);
			padding-right: 80px;
			p {
				margin-bottom: 57px;
			}
		}
		&__right {
			flex: 0 0 540px;
			max-width: 540px;
		}
		&__center {
			flex: 0 0 645px;
			max-width: 645px;
			text-align: center;
			margin: 0 auto;
			color: var(--c-neutral-white);
			figure {
				margin-bottom: 43px;
			}
			.button-outline {
				border-color: var(--c-primary-white);
				color: var(--c-primary-white);
			}
		}
		h6 {
			color: var(--c-primary-main);
			margin-bottom: 8px;
		}
		h2 {
			margin-bottom: 24px;
		}
	}
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.misc {
		&__wrapper {
			max-width: 790px;
			margin: 0 auto;
		}
		.wrap-misc {
			padding: 100px 0;
			&__left {
				flex: 0 0 calc(100% - 400px);
				max-width: calc(100% - 400px);
			}
			&__right {
				flex: 0 0 400px;
				max-width: 400px;
			}
			&__center {
				flex: 0 0 484px;
				max-width: 484px;
				&.undermaintenance {
					flex: unset;
					max-width: unset;
				}
			}
		}
	}
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
	.misc {		
		min-height: 100vh;
		height: auto;
		&__wrapper {
			max-width: 100%;
			margin: 0 40px;			
		}
		.wrap-misc {
			flex-direction: column-reverse;
			text-align: center;
			justify-content: center;			
			padding: 50px 0;
			min-height: 100vh; 
			height: auto;
			&__left {
				flex: unset;
				max-width: 480px;
				padding: 0;
			}
			&.not-column {
				flex-direction: unset;
			}
		}
	}
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.misc {		
		&__wrapper {
			margin: 0 16px;			
		}
		.wrap-misc {
			padding: 30px 0; 			
			&__right {
				flex: unset;
				max-width: 300px;
				figure {
					margin-bottom: 30px;
				}
			}
			&__center {
				flex: unset;
			}
		}
	}
}
